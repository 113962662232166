import React from 'react';

import Layout from '../components/layout/layout';
import About from "../components/about/about";

const AboutPage = () => {
  return (
    <Layout>
      <About />
    </Layout>
  )
}

export default AboutPage
